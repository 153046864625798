import React, { useState } from "react";
import { getUserComments } from "../../services/user-social.service";

export interface TwitterComment {
    id: string;
    text: string;
    tweetId: string;
    parentTweetId: string | null;
    createdAt: string;
    user: {
        twitterUsername: string;
    };
}

export default function UserComments() {
    const [username, setUsername] = useState<string>("");
    const [comments, setComments] = useState<TwitterComment[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>("");

    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!username.trim()) {
            setError("Please enter a username");
            return;
        }

        try {
            setLoading(true);
            setError("");
            const data = await getUserComments(username.trim());
            setComments(data);
        } catch (err) {
            setError("Failed to fetch user comments");
            setComments([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">User Comments</h2>

            <form onSubmit={handleSearch} className="mb-6">
                <div className="flex gap-3">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter Twitter username"
                        className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        disabled={loading}
                        className={`px-4 py-2 rounded-md text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
              ${loading
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-blue-500 hover:bg-blue-600'
                            }`}
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </div>
            </form>

            {error && (
                <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-600">
                    {error}
                </div>
            )}

            {loading && (
                <div className="text-center py-4">
                    <div className="animate-pulse text-gray-600">Loading comments...</div>
                </div>
            )}

            {!loading && comments.length === 0 && username && !error && (
                <div className="text-center py-4 text-gray-500">
                    No comments found for this user
                </div>
            )}

            {comments.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Username
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Comment
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Tweet Link
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {comments.map((comment) => (
                                <tr key={comment.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <a
                                            href={`https://twitter.com/${comment.user.twitterUsername}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            @{comment.user.twitterUsername}
                                        </a>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="text-sm text-gray-900">
                                            {comment.text}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <a
                                            href={`https://twitter.com/anyuser/status/${comment.tweetId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            View Tweet
                                        </a>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {new Date(comment.createdAt).toLocaleDateString()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}