import React from 'react';
import { useApiKey } from '../context/ApiKeyContext';

interface PermissionGateProps {
    permissions: string | string[];
    require?: 'all' | 'any';
    children: React.ReactNode;
    fallback?: React.ReactNode;
}

export const PermissionGate: React.FC<PermissionGateProps> = ({
    permissions,
    require = 'any',
    children,
    fallback = null
}) => {
    const { hasPermission, hasAllPermissions, hasAnyPermission } = useApiKey();

    const hasAccess = (): boolean => {
        if (typeof permissions === 'string') {
            return hasPermission(permissions);
        }
        return require === 'all' ? hasAllPermissions(permissions) : hasAnyPermission(permissions);
    };

    return hasAccess() ? <>{children}</> : <>{fallback}</>;
};