import React, { useState } from 'react'
import { postNewTweet } from '../../../services/user-social.service';

const TweetsManagement = () => {
    const [url, setUrl] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');

    const extractTweetId = (url: string): string | null => {
        const patterns = [
            /twitter\.com\/\w+\/status\/(\d+)/,
            /twitter\.com\/statuses\/(\d+)/,
            /x\.com\/\w+\/status\/(\d+)/,
            /\/status\/(\d+)/
        ];

        for (const pattern of patterns) {
            const match = url.match(pattern);
            if (match && match[1]) {
                return match[1];
            }
        }
        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (!url.trim()) {
            setError('Please enter a Tweet URL');
            return;
        }

        const tweetId = extractTweetId(url.trim());
        if (!tweetId) {
            setError('Invalid Tweet URL format. Please enter a valid Twitter status URL');
            return;
        }

        try {
            const res = await postNewTweet(tweetId);
            if (res.authorId) {
                setSuccess("Tweet created");
                setUrl('');
            }
        } catch (error) {
            setError('Error creating tweet.')
        }
    };

    return (
        <>
            <div className="bg-white rounded-lg shadow p-6">
                <h1 className="text-xl font-semibold text-gray-800">Tweets Management</h1>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-6">Add Hub Tweet</h2>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="tweetUrl" className="block text-sm font-medium text-gray-700 mb-1">
                            Tweet URL
                        </label>
                        <div className="flex gap-3">
                            <input
                                id="tweetUrl"
                                type="text"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                placeholder="Paste Tweet URL here"
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                            <button
                                type="submit"
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
                            >
                                Save
                            </button>
                        </div>
                        {error && (
                            <p className="mt-2 text-sm text-red-600">{error}</p>
                        )}
                        {success && (
                            <p className="mt-2 text-sm text-green-600">{success}</p>
                        )}
                    </div>

                    <div className="text-sm text-gray-600">
                        <p>Accepted URL formats:</p>
                        <ul className="list-disc ml-5 space-y-1">
                            <li>https://twitter.com/username/status/123456789</li>
                            <li>https://x.com/username/status/123456789</li>
                            <li>https://twitter.com/statuses/123456789</li>
                        </ul>
                    </div>
                </form>
            </div>
        </>
    )
}

export default TweetsManagement