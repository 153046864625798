import React from "react";
import {
  Quest,
  QuestTypes,
  QuestCTACategories,
  QuestCTAIdentifiers,
  TwitterCTAData,
  QuestCTAData,
  WebsiteCTAData,
} from "./types";

interface CreateEditQuestFormProps {
  formData: Partial<Quest>;
  setFormData: React.Dispatch<React.SetStateAction<Partial<Quest>>>;
  onSubmit: (questData: Partial<Quest>) => void;
  editingId: string | null;
}

const CreateEditQuestForm: React.FC<CreateEditQuestFormProps> = ({
  formData,
  setFormData,
  onSubmit,
  editingId,
}) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name.startsWith("ctaData.")) {
      const ctaDataField = name.split(".")[1];
      setFormData({
        ...formData,
        ctaData: {
          ...(formData.ctaData as QuestCTAData),
          [ctaDataField]: value,
        } as QuestCTAData,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h2 style={{ marginBottom: "15px" }}>
        {editingId ? "Edit Quest" : "Create New Quest"}
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "10px",
        }}
      >
        <div>
          <label
            htmlFor="description"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Description:
          </label>
          <input
            id="description"
            name="description"
            value={formData.description || ""}
            onChange={handleInputChange}
            placeholder="Description"
            required
            style={{ width: "100%", padding: "5px" }}
          />
        </div>
        <div>
          <label
            htmlFor="type"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Type:
          </label>
          <select
            id="type"
            name="type"
            value={formData.type || ""}
            onChange={handleInputChange}
            required
            style={{ width: "100%", padding: "5px" }}
          >
            <option value="">Select Type</option>
            {Object.keys(QuestTypes).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="totalChecks"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Total Checks:
          </label>
          <input
            id="totalChecks"
            name="totalChecks"
            type="number"
            value={formData.totalChecks || ""}
            onChange={handleInputChange}
            placeholder="Total Checks"
            required
            style={{ width: "100%", padding: "5px" }}
          />
        </div>
        <div>
          <label
            htmlFor="validTo"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Valid To:
          </label>
          <input
            id="validTo"
            name="validTo"
            type="datetime-local"
            value={
              formData.validTo
                ? new Date(formData.validTo).toISOString().slice(0, 16)
                : ""
            }
            onChange={handleInputChange}
            placeholder="Valid To"
            style={{ width: "100%", padding: "5px" }}
          />
        </div>
        <div>
          <label
            htmlFor="score"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Score:
          </label>
          <input
            id="score"
            name="score"
            type="number"
            value={formData.score || ""}
            onChange={handleInputChange}
            placeholder="Score"
            required
            style={{ width: "100%", padding: "5px" }}
          />
        </div>
        <div>
          <label
            htmlFor="verificationCode"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Verification Code:
          </label>
          <input
            id="verificationCode"
            name="verificationCode"
            value={formData.verificationCode || ""}
            onChange={handleInputChange}
            placeholder="Verification Code"
            style={{ width: "100%", padding: "5px" }}
          />
        </div>
        <div>
          <label
            htmlFor="ctaCategory"
            style={{ display: "block", marginBottom: "5px" }}
          >
            CTA Category:
          </label>
          <select
            id="ctaCategory"
            name="ctaCategory"
            value={formData.ctaCategory || ""}
            onChange={handleInputChange}
            required
            style={{ width: "100%", padding: "5px" }}
          >
            <option value="">Select CTA Category</option>
            {Object.keys(QuestCTACategories).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="ctaIdentifier"
            style={{ display: "block", marginBottom: "5px" }}
          >
            CTA Identifier:
          </label>
          <select
            id="ctaIdentifier"
            name="ctaIdentifier"
            value={formData.ctaIdentifier || ""}
            onChange={handleInputChange}
            required
            style={{ width: "100%", padding: "5px" }}
          >
            <option value="">Select CTA Identifier</option>
            {Object.keys(QuestCTAIdentifiers).map((identifier) => (
              <option key={identifier} value={identifier}>
                {identifier}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="isEnabled"
            style={{ display: "block", marginBottom: "5px" }}
          >
            <input
              id="isEnabled"
              name="isEnabled"
              type="checkbox"
              checked={formData.isEnabled || false}
              onChange={(e) =>
                setFormData({ ...formData, isEnabled: e.target.checked })
              }
              style={{ marginRight: "5px" }}
            />
            Is Enabled
          </label>
        </div>
        {formData.ctaCategory === "TWITTER_CONTENT" && (
          <div style={{ gridColumn: "1 / -1" }}>
            <label
              htmlFor="ctaData.twitterAccount"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Twitter Username:
            </label>
            <input
              id="ctaData.twitterAccount"
              name="ctaData.twitterAccount"
              value={(formData.ctaData as TwitterCTAData)?.twitterAccount || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ctaData: {
                    ...formData.ctaData,
                    twitterAccount: e.target.value,
                  } as TwitterCTAData,
                })
              }
              placeholder="Twitter Username"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
            />
            <label
              htmlFor="ctaData.tweetLink"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Tweet Link:
            </label>
            <input
              id="ctaData.tweetLink"
              name="ctaData.tweetLink"
              value={(formData.ctaData as TwitterCTAData)?.tweetLink || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ctaData: {
                    ...formData.ctaData,
                    tweetLink: e.target.value,
                  } as TwitterCTAData,
                })
              }
              placeholder="Tweet Link"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
            />
            <label
              htmlFor="ctaData.isOnHubFeed"
              style={{ display: "block", marginBottom: "5px" }}
            >
              <input
                id="ctaData.isOnHubFeed"
                name="ctaData.isOnHubFeed"
                type="checkbox"
                checked={
                  (formData.ctaData as TwitterCTAData)?.isOnHubFeed || false
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    ctaData: {
                      ...formData.ctaData,
                      isOnHubFeed: e.target.checked,
                    } as TwitterCTAData,
                  })
                }
                style={{ marginRight: "5px" }}
              />
              Is On Hub Feed
            </label>
          </div>
        )}
        {formData.ctaCategory === "WEBSITE_CONTENT" && (
          <div style={{ gridColumn: "1 / -1" }}>
            <label
              htmlFor="ctaData.url"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Website URL:
            </label>
            <input
              id="ctaData.url"
              name="ctaData.url"
              value={(formData.ctaData as WebsiteCTAData)?.url || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ctaData: {
                    ...formData.ctaData,
                    url: e.target.value,
                  } as WebsiteCTAData,
                })
              }
              placeholder="Website URL"
              style={{ width: "100%", padding: "5px" }}
            />
          </div>
        )}
        <div style={{ gridColumn: "1 / -1", marginTop: "10px" }}>
          <button
            type="submit"
            style={{
              padding: "10px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100%",
            }}
          >
            {editingId ? "Update" : "Create"} Quest
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateEditQuestForm;
