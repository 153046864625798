import React from "react";
import { Quest } from "./types";

interface QuestListProps {
  quests: Quest[];
  onEdit: (quest: Quest) => void;
  onDelete: (id: string) => void;
}

const QuestList: React.FC<QuestListProps> = ({ quests, onEdit, onDelete }) => {
  const tableHeaderStyle = {
    padding: "12px",
    textAlign: "left" as const,
    borderBottom: "2px solid #ddd",
  };

  const tableCellStyle = {
    padding: "12px",
    textAlign: "left" as const,
  };

  const editButtonStyle = {
    marginRight: "5px",
    padding: "5px 10px",
    backgroundColor: "#2196F3",
    color: "white",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  };

  const deleteButtonStyle = {
    padding: "5px 10px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  };

  const tableContainerStyle = {
    overflowX: "auto" as const,
    position: "relative" as const,
    maxWidth: "100%",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "separate" as const,
    borderSpacing: 0,
  };

  const stickyColumnStyle = {
    position: "sticky" as const,
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
  };

  const stickyHeaderStyle = {
    ...stickyColumnStyle,
    zIndex: 2,
    backgroundColor: "#f2f2f2",
  };

  return (
    <>
      <h2 style={{ marginBottom: "15px" }}>Quest List</h2>
      <div style={tableContainerStyle}>
        <table style={tableStyle}>
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Total Checks</th>
              <th style={tableHeaderStyle}>Valid From</th>
              <th style={tableHeaderStyle}>Valid To</th>
              <th style={tableHeaderStyle}>Enabled</th>
              <th style={tableHeaderStyle}>Score</th>
              <th style={tableHeaderStyle}>Verification Code</th>
              <th style={tableHeaderStyle}>CTA Category</th>
              <th style={tableHeaderStyle}>CTA Identifier</th>
              <th
                style={{ ...tableHeaderStyle, ...stickyHeaderStyle, right: 0 }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {quests.map((quest) => (
              <tr key={quest.id} style={{ borderBottom: "1px solid #ddd" }}>
                <td style={tableCellStyle}>{quest.description}</td>
                <td style={tableCellStyle}>{quest.type}</td>
                <td style={tableCellStyle}>{quest.totalChecks}</td>
                <td style={tableCellStyle}>
                  {new Date(quest.validFrom).toLocaleString()}
                </td>
                <td style={tableCellStyle}>
                  {quest.validTo
                    ? new Date(quest.validTo).toLocaleString()
                    : "N/A"}
                </td>
                <td style={tableCellStyle}>{quest.isEnabled ? "Yes" : "No"}</td>
                <td style={tableCellStyle}>{quest.score}</td>
                <td style={tableCellStyle}>
                  {quest.verificationCode || "N/A"}
                </td>
                <td style={tableCellStyle}>{quest.ctaCategory}</td>
                <td style={tableCellStyle}>{quest.ctaIdentifier}</td>
                <td
                  style={{ ...tableCellStyle, ...stickyColumnStyle, right: 0 }}
                >
                  <button onClick={() => onEdit(quest)} style={editButtonStyle}>
                    Edit
                  </button>
                  <button
                    onClick={() => onDelete(quest.id)}
                    style={deleteButtonStyle}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default QuestList;
