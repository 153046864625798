import { TwitterComment } from "../components/Social admin/UserMessages";
import { fetchClient, IDefaultResponse } from "./fetchClient";

export const UpdateUserBot = async (username: string, isBot: boolean): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user-social/update-user-bot`, { username, isBot });
  return res.data;
};
export const fetchUserFlaggedBot = async (): Promise<any> => {
  const res = await fetchClient.get<any>(`/user-social/flagged-bot`);
  return res.data;
};

export const softDeleteUser = async (username: string): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user-social/soft-delete`, { username });
  return res.data;
};

export const updateUserPoints = async (username: string, points: number): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user-social/update-points`, {
    username,
    points
  });
  return res.data;
};

export const getUserComments = async (username: string): Promise<TwitterComment[]> => {
  const res = await fetchClient.get<TwitterComment[]>(`/user-social/${username}/comments`);
  return res.data;
};

export const postNewTweet = async (id: string): Promise<any> => {
  const res = await fetchClient.post<any>(`/user-social/create-tweet`, { id });
  return res.data;
};