interface RequestOptions extends RequestInit {
    headers?: Record<string, string>;
}

interface ApiResponse<T> {
    data: T;
    status: number;
}

export interface IDefaultResponse {
    error?: string;
    message: string;
}

class FetchClient {
    private baseUrl: string;
    private apiKey: string | null = localStorage.getItem('apiKey');
    static instance: FetchClient | null = null;


    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';
        this.apiKey = localStorage.getItem('apiKey');
    }

    static getInstance() {
        if (!FetchClient.instance) {
            FetchClient.instance = new FetchClient();
        }
        return FetchClient.instance;
    }

    setApiKey(key: string): void {
        this.apiKey = key;
    }

    private getApiKey(): string {
        // If apiKey is not set, try to get it from localStorage
        if (!this.apiKey) {
            this.apiKey = localStorage.getItem('apiKey') || '';
        }
        return this.apiKey;
    }

    async request<T>(endpoint: string, options: RequestOptions = {}): Promise<ApiResponse<T>> {
        const url = `${this.baseUrl}${endpoint}`;
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
            ...options.headers
        };

        const apiKey = this.getApiKey();
        if (apiKey) {
            headers['X-API-Key'] = apiKey;
        }

        const config: RequestInit = {
            ...options,
            headers
        };

        try {
            const response = await fetch(url, config);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return { data, status: response.status };
        } catch (error) {
            throw error;
        }
    }

    async get<T>(endpoint: string): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, { method: 'GET' });
    }

    async post<T>(endpoint: string, data: unknown): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    async put<T>(endpoint: string, data: unknown): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, {
            method: 'PUT',
            body: JSON.stringify(data)
        });
    }

    async delete<T>(endpoint: string): Promise<ApiResponse<T>> {
        return this.request<T>(endpoint, { method: 'DELETE' });
    }
}

export const fetchClient = FetchClient.getInstance();