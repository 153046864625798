import React, { useState } from 'react'
import UserProfileView from './UserProfileView'
import ReferralUserTweets from './ReferralUserTweets'
import { PermissionGate } from '../PermissionGate';
import { PERMISSION_GROUPS } from '../../constants/permissions';

const SocialAdmin = () => {

    const [selectedUsername, setSelectedUsername] = useState<string>('');

    const handleUsernameSelect = (username: string) => {
        setSelectedUsername(username);
    }

    return (
        <>
            <div className="bg-white rounded-lg shadow p-6">
                <h1 className="text-xl font-semibold text-gray-800">Social Admin</h1>
            </div>
            <UserProfileView
                selectedUsername={selectedUsername}
            />
            <PermissionGate permissions={PERMISSION_GROUPS.SUPER_ADMIN} >
                <ReferralUserTweets onUsernameSelect={handleUsernameSelect} />
            </PermissionGate>
        </>
    )
}

export default SocialAdmin