import React, { useState, useEffect } from "react";
import { fetchTweetsDb } from "../../services/user-social.service";
import { Tweet2 } from "./types";
import {
  FollowerTier,
  groupTweetsByFollowerTier,
} from "../../utils/tweets.util";
import * as XLSX from "xlsx";

interface ReferralUserTweetsProps {
  onUsernameSelect: (username: string) => void;
}

// Define tier labels for display
const TIER_LABELS = {
  0: "Tier 0 (<100 followers)",
  1: "Tier 1 (100-999 followers)",
  2: "Tier 2 (1,000-4,999 followers)",
  3: "Tier 3 (5,000-9,999 followers)",
  4: "Tier 4 (10,000-49,999 followers)",
  5: "Tier 5 (50,000-99,999 followers)",
  6: "Tier 6 (100,000+ followers)",
};

const ReferralUserTweets: React.FC<ReferralUserTweetsProps> = ({
  onUsernameSelect,
}) => {
  const [tweets, setTweets] = useState<Tweet2[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTier, setActiveTier] = useState<number | null>(null);

  useEffect(() => {
    const fetchTweets = async () => {
      try {
        const response = await fetchTweetsDb();
        setTweets(response.tweets);
      } catch (err) {
        setError(err as string);
      } finally {
        setLoading(false);
      }
    };

    fetchTweets();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading tweets...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  const groupedTweets = groupTweetsByFollowerTier(tweets);

  // Get total counts for each tier
  const tierCounts = Object.entries(groupedTweets).reduce(
    (acc, [tier, tweetList]) => {
      acc[Number(tier)] = tweetList.length;
      return acc;
    },
    {} as Record<number, number>,
  );

  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    }
    return num.toString();
  };

  // Process tweets into user data for table display
  const processUserData = (tweetsList: Tweet2[]) => {
    // Create a map to store unique users and their tweet counts
    const userMap = new Map<
      string,
      {
        username: string;
        followers: number;
        tweetCount: number;
      }
    >();

    // Process each tweet to extract user info
    tweetsList.forEach((tweet) => {
      const username = tweet.author_data.username;
      const followers = tweet.author_data.public_metrics.followers_count;

      if (userMap.has(username)) {
        // Update existing user's tweet count
        const userData = userMap.get(username)!;
        userMap.set(username, {
          ...userData,
          tweetCount: userData.tweetCount + 1,
        });
      } else {
        // Add new user
        userMap.set(username, {
          username,
          followers,
          tweetCount: 1,
        });
      }
    });

    // Convert map to array and sort by follower count (descending)
    return Array.from(userMap.values()).sort(
      (a, b) => b.followers - a.followers,
    );
  };

  const tweetsToDisplay =
    activeTier !== null ? groupedTweets[activeTier as FollowerTier] : tweets;

  const userData = processUserData(tweetsToDisplay);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">
        Twitter Users by Follower Tier
      </h1>

      {/* Tier selection tabs */}
      <div className="mb-6">
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setActiveTier(null)}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTier === null
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            All Tiers ({tweets.length})
          </button>
          {Object.entries(TIER_LABELS).map(([tier, label]) => (
            <button
              key={tier}
              onClick={() => setActiveTier(Number(tier))}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                activeTier === Number(tier)
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
              }`}
            >
              {label} ({tierCounts[Number(tier)] || 0})
            </button>
          ))}
        </div>
      </div>

      <div className="flex items-center w-full justify-between">
        {/* Display user count summary */}
        <div className="text-gray-600">
          {activeTier !== null
            ? `Showing ${userData.length} users in ${
                TIER_LABELS[activeTier as keyof typeof TIER_LABELS]
              }`
            : `Showing all ${userData.length} users across all tiers`}
        </div>
        {/* Excel Export Button */}
        {userData.length > 0 && (
          <div className="text-right">
            <button
              onClick={() => exportToExcel(userData)}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-200 flex items-center ml-auto"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              Export to Excel
            </button>
          </div>
        )}
      </div>
      {/* User data table */}
      <div className="overflow-x-auto bg-white rounded-lg shadow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Followers
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Tweets
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Tier
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {userData.map((user) => {
              // Determine tier based on follower count
              const userTier = (() => {
                const followers = user.followers;
                if (followers < 100) return 0;
                if (followers < 1000) return 1;
                if (followers < 5000) return 2;
                if (followers < 10000) return 3;
                if (followers < 50000) return 4;
                if (followers < 100000) return 5;
                return 6;
              })();

              return (
                <tr key={user.username} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="font-medium text-gray-900">
                      @{user.username}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-gray-500">
                      {formatNumber(user.followers)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-gray-500">{user.tweetCount}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                      ${
                        userTier === 0
                          ? "bg-gray-100 text-gray-800"
                          : userTier === 1
                          ? "bg-blue-100 text-blue-800"
                          : userTier === 2
                          ? "bg-green-100 text-green-800"
                          : userTier === 3
                          ? "bg-yellow-100 text-yellow-800"
                          : userTier === 4
                          ? "bg-orange-100 text-orange-800"
                          : userTier === 5
                          ? "bg-purple-100 text-purple-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      Tier {userTier}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => onUsernameSelect(user.username)}
                      className="text-blue-600 hover:text-blue-900 px-3 py-1 bg-blue-50 hover:bg-blue-100 rounded-md transition-colors duration-200"
                    >
                      View Profile
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {userData.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No users found in this tier
        </div>
      )}
    </div>
  );

  // Function to export data to Excel
  function exportToExcel(data: any[]) {
    // Prepare the data for export
    const exportData = data.map((user) => ({
      Username: `@${user.username}`,
      Followers: user.followers,
      "Follower Count": formatNumber(user.followers), // Human-readable format
      "Tweet Count": user.tweetCount,
      Tier: `Tier ${(() => {
        const followers = user.followers;
        if (followers < 100) return 0;
        if (followers < 1000) return 1;
        if (followers < 5000) return 2;
        if (followers < 10000) return 3;
        if (followers < 50000) return 4;
        if (followers < 100000) return 5;
        return 6;
      })()}`,
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Set column widths
    const columnWidths = [
      { wch: 20 }, // Username
      { wch: 12 }, // Followers (raw number)
      { wch: 15 }, // Followers (formatted)
      { wch: 12 }, // Tweet Count
      { wch: 10 }, // Tier
    ];
    worksheet["!cols"] = columnWidths;

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Twitter Users");

    // Generate Excel file and trigger download
    const fileName = `Twitter_Users_${
      activeTier !== null ? `Tier${activeTier}` : "AllTiers"
    }_${new Date().toISOString().split("T")[0]}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  }
};

export default ReferralUserTweets;
