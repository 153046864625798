import { fetchClient } from './fetchClient';

export interface Permission {
    name: string;
    description?: string;
}

export interface PermissionGroup {
    name: string;
    permissions: string[];
}

export interface PermissionResponse {
    permissions: Record<string, string>;
    permissionGroups: Record<string, string[]>;
}

export const getPermissions = async (): Promise<PermissionResponse> => {
    const res = await fetchClient.get<PermissionResponse>('/permissions');
    return res.data;
};

export const getKeyPermissions = async (apiKey: string): Promise<string[]> => {
    const res = await fetchClient.get<{ permissions: string[] }>(`/permissions/key/${apiKey}`);
    return res.data.permissions;
};

export const updateKeyPermissions = async (apiKey: string, permissions: string[]): Promise<void> => {
    await fetchClient.post(`/permissions/key/${apiKey}`, { permissions });
};