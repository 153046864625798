import React, { useState } from 'react';
import { useApiKey } from '../context/ApiKeyContext';
import { getKeyPermissions } from '../services/permissions.service';

export const ApiKeyInput: React.FC = () => {
    const { apiKey, updateApiKey, updatePermissions } = useApiKey();
    const [inputKey, setInputKey] = useState<string>(apiKey);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const permissions = await getKeyPermissions(inputKey);
            updateApiKey(inputKey);
            updatePermissions(permissions);
        } catch (err) {
            setError('Invalid API key or insufficient permissions');
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setInputKey('');
        updateApiKey('');
        updatePermissions([]);
        localStorage.removeItem('apiKey');
    };

    return (
        <div className="flex w-full h-[100vh] justify-center items-center bg-white rounded-lg shadow-sm p-4">
            <form onSubmit={handleSubmit} className="space-y-4 w-[50%]">
                <div>
                    <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">
                        API Key
                    </label>
                    <input
                        type="text"
                        id="apiKey"
                        value={inputKey}
                        onChange={(e) => setInputKey(e.target.value)}
                        placeholder="Enter your API key"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                {error && (
                    <div className="text-sm text-red-600 bg-red-50 border border-red-100 rounded-md p-3">
                        {error}
                    </div>
                )}

                <div className="flex space-x-3">
                    <button
                        type="submit"
                        disabled={loading}
                        className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {loading ? 'Validating...' : 'Save API Key'}
                    </button>
                    <button
                        type="button"
                        onClick={handleClear}
                        className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
};