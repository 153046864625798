import React, { createContext, useContext, useState, useEffect } from 'react';
import { getKeyPermissions } from '../services/permissions.service';
import { fetchClient } from '../services/fetchClient';

interface ApiKeyContextType {
    apiKey: string;
    permissionGroups: string[];
    permissions: string[];
    updateApiKey: (key: string) => void;
    updatePermissions: (permissions: string[]) => void;
    hasPermission: (requiredPermission: string) => boolean;
    hasAnyPermission: (requiredPermissions: string[]) => boolean;
    hasAllPermissions: (requiredPermissions: string[]) => boolean;
}

const ApiKeyContext = createContext<ApiKeyContextType | undefined>(undefined);

export const ApiKeyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [apiKey, setApiKey] = useState<string>(() => {
        return localStorage.getItem('apiKey') || '';
    });
    const [permissions, setPermissions] = useState<string[]>([]);
    const [permissionGroups, setPermissionGroups] = useState<string[]>([]);

    useEffect(() => {
        if (apiKey) {
            fetchClient.setApiKey(apiKey);
            loadPermissions(apiKey);
        }
    }, [apiKey]);

    const loadPermissions = async (key: string) => {
        try {
            const perms = await getKeyPermissions(key);
            setPermissions(perms);
            fetchClient.setApiKey(key);
            const groups = determinePermissionGroups(perms);
            setPermissionGroups(groups);
        } catch (error) {
            console.error('Error loading permissions:', error);
        }
    };

    const determinePermissionGroups = (perms: string[]): string[] => {
        const groups: string[] = [];

        // Check for group membership based on permissions
        if (perms.includes('super_admin')) {
            groups.push('SUPER_ADMIN');
        }
        if (perms.includes('update_pages')) {
            groups.push('ADMIN');
        }
        if (perms.includes('whitelist_users')) {
            groups.push('CM');
        }
        if (perms.includes('manage_user_social')) {
            groups.push('AI_MODERATOR');
        }

        return groups;
    };

    const updateApiKey = (key: string) => {
        setApiKey(key);
        localStorage.setItem('apiKey', key);
        fetchClient.setApiKey(key);
        if (key) {
            loadPermissions(key);
        } else {
            setPermissions([]);
            setPermissionGroups([]);
        }
    };

    const updatePermissions = (newPermissions: string[]) => {
        setPermissions(newPermissions);
        const groups = determinePermissionGroups(newPermissions);
        setPermissionGroups(groups);
    };

    const hasPermission = (requiredPermission: string): boolean => {
        return permissions.includes(requiredPermission) ||
            permissionGroups.includes('SUPER_ADMIN');
    };

    const hasAnyPermission = (requiredPermissions: string[]): boolean => {
        return requiredPermissions.some(perm => hasPermission(perm));
    };

    const hasAllPermissions = (requiredPermissions: string[]): boolean => {
        return requiredPermissions.every(perm => hasPermission(perm));
    };

    return (
        <ApiKeyContext.Provider value={{
            apiKey,
            permissions,
            permissionGroups,
            updateApiKey,
            updatePermissions,
            hasPermission,
            hasAnyPermission,
            hasAllPermissions
        }}>
            {children}
        </ApiKeyContext.Provider>
    );
};

export const useApiKey = () => {
    const context = useContext(ApiKeyContext);
    if (context === undefined) {
        throw new Error('useApiKey must be used within an ApiKeyProvider');
    }
    return context;
};