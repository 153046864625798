import { Quest } from "../components/Quests/types";
import { BASE_URL } from "../constants";


export const questService = {
  listQuests: async (): Promise<Quest[]> => {
    const response = await fetch(`${BASE_URL}/quest`);
    if (!response.ok) {
      throw new Error("Failed to fetch quests");
    }
    return response.json();
  },

  createQuest: async (key: string, quest: Partial<Quest>): Promise<Quest> => {
    const response = await fetch(`${BASE_URL}/quest`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...quest,
        key,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to create quest");
    }
    return response.json();
  },

  updateQuest: async (key: string, id: string, quest: Partial<Quest>): Promise<Quest> => {
    const response = await fetch(`${BASE_URL}/quest/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...quest,
        key,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to update quest");
    }
    return response.json();
  },

  deleteQuest: async (key: string, id: string): Promise<void> => {
    const response = await fetch(`${BASE_URL}/quest/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to delete quest");
    }
  },
};
