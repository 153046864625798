import React from "react";
import { useApiKey } from "../context/ApiKeyContext";
import { NAV_PERMISSION_GROUPS, hasRequiredPermissionsForNav } from "../constants/nav-permissions";
import {
  Users,
  FileText,
  Database,
  Trophy,
  UserCog,
  MessageSquare,
  LibraryBig,
} from "lucide-react";

interface NavMenuProps {
  handleOnClickTab: (index: number) => void;
  selectedTab: number;
}

const ICONS = {
  Users,
  FileText,
  Database,
  Trophy,
  UserCog,
  MessageSquare,
  LibraryBig,
};

const NavMenu: React.FC<NavMenuProps> = ({
  handleOnClickTab,
  selectedTab,
}) => {
  const { permissions } = useApiKey();

  const navItems = Object.entries(NAV_PERMISSION_GROUPS)
    .filter(([key]) => hasRequiredPermissionsForNav(permissions, key as keyof typeof NAV_PERMISSION_GROUPS))
    .sort((a, b) => a[1].index - b[1].index)
    .map(([_, item]) => ({
      ...item,
      Icon: ICONS[item.icon as keyof typeof ICONS]
    }));

  return (
    <div className="w-64 bg-white border-r border-gray-200 h-screen overflow-y-auto">
      <div className="px-4 py-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-6">
          Admin Dashboard
        </h2>
        <nav className="space-y-1">
          {navItems.map((item) => (
            <div
              key={item.index}
              className={`
                group flex items-center px-3 py-3 rounded-lg cursor-pointer
                transition-all duration-150 ease-in-out
                ${selectedTab === item.index
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-700 hover:bg-gray-50'
                }
              `}
              onClick={() => handleOnClickTab(item.index)}
            >
              <item.Icon
                className={`
                  mr-3 flex-shrink-0 w-5 h-5
                  ${selectedTab === item.index
                    ? 'text-blue-700'
                    : 'text-gray-500 group-hover:text-gray-700'
                  }
                `}
              />
              <div>
                <span className="font-medium">{item.label}</span>
                <p className={`
                  text-xs mt-0.5
                  ${selectedTab === item.index
                    ? 'text-blue-600'
                    : 'text-gray-500'
                  }
                `}>
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default NavMenu;
