import React, { useState } from 'react';
import { addBonusPoint } from '../../services/user-social.service';

interface PointsBoostProps {
    username: string;
    onSuccess?: (response: any) => void;
    onError?: (error: string) => void;
}

export const PointsBoost: React.FC<PointsBoostProps> = ({
    username,
    onSuccess,
    onError
}) => {
    const [points, setPoints] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccess('');

        try {
            const response = await addBonusPoint({
                username,
                points: Number(points)
            })

            setSuccess(`Added ${response.points} points.`);
            setPoints('');
            onSuccess?.(response);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to add points';
            onError?.(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="space-y-4 bg-blue-50 p-4 rounded-lg">
            <h3 className="font-medium text-blue-800">Add Referral Points</h3>
            <form onSubmit={handleSubmit} className="space-y-3">
                <div>
                    <input
                        type="number"
                        value={points}
                        onChange={(e) => setPoints(e.target.value)}
                        placeholder="Enter base points"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        min="1"
                        required
                    />
                </div>
                <button
                    type="submit"
                    disabled={loading || !points}
                    className={`w-full py-2 px-4 rounded-md text-white transition-colors duration-200 
                        ${loading || !points
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-blue-600 hover:bg-blue-700'}`}
                >
                    {loading ? 'Adding Points...' : 'Add Points'}
                </button>
            </form>
            {success && (
                <div className="p-3 bg-green-50 border border-green-200 rounded-md text-green-700">
                    {success}
                </div>
            )}
        </div>
    );
};