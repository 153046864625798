import React from "react";

interface INavMenu {
  handleOnClickTab: (index: number) => void;
  selectedTab: number;
}

export default function NavMenu({ handleOnClickTab, selectedTab }: INavMenu) {
  return (
    <div className="f-column pointer nav-menu">
      <div
        className={`nav-item ${selectedTab === 0 && "selected"}`}
        onClick={() => handleOnClickTab(0)}
      >
        User
      </div>
      <div
        className={`nav-item ${selectedTab === 1 && "selected"}`}
        onClick={() => handleOnClickTab(1)}
      >
        Collection
      </div>
      <div
        className={`nav-item ${selectedTab === 2 && "selected"}`}
        onClick={() => handleOnClickTab(2)}
      >
        Pages
      </div>
      <div
        className={`nav-item ${selectedTab === 3 && "selected"}`}
        onClick={() => handleOnClickTab(3)}
      >
        Airtable
      </div>
      <div
        className={`nav-item ${selectedTab === 4 && "selected"}`}
        onClick={() => handleOnClickTab(4)}
      >
        Quests
      </div>
    </div>
  );
}
