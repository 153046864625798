import React from "react";

interface INavMenu {
  handleOnClickTab: (index: number) => void;
  selectedTab: number;
}

export default function NavMenu({ handleOnClickTab, selectedTab }: INavMenu) {
  const menuItems = [
    { id: 0, name: "User" },
    { id: 1, name: "Collection" },
    { id: 2, name: "Pages" },
    { id: 3, name: "Airtable" },
    { id: 4, name: "Quests" },
    { id: 5, name: "AI User" },
    { id: 6, name: "Tweets management" }
  ];

  return (
    <nav className="w-64 bg-white border-r border-gray-200 h-full">
      <div className="flex flex-col pt-6">
        {menuItems.map((item) => (
          <button
            key={item.id}
            onClick={() => handleOnClickTab(item.id)}
            className={`px-4 py-2 text-left hover:bg-gray-100 transition-colors duration-150 ${selectedTab === item.id
              ? "bg-blue-500 text-white hover:bg-blue-600"
              : "text-gray-700"
              }`}
          >
            {item.name}
          </button>
        ))}
      </div>
    </nav>
  );
}