import React, { useEffect, useState } from 'react';
import { getUserComments, UpdateUserBot, updateUserPoints, softDeleteUser, updateTweetPoints, deleteUserPoint } from '../../services/user-social.service';
import { Tweet, UserPoints, UserResponse } from './types';
import Pagination from '../Pagination';
import { PointsBoost } from './PointBoost';
import { PermissionGate } from '../PermissionGate';
import { PERMISSION_GROUPS } from '../../constants/permissions';

interface EditingPointState {
    pointId: string;
    newValue: string;
}

interface UserProfileViewProps {
    selectedUsername: string;
}

const UserProfileView: React.FC<UserProfileViewProps> = ({ selectedUsername }) => {
    const [username, setUsername] = useState('');
    const [userData, setUserData] = useState<UserResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [editingPoints, setEditingPoints] = useState(false);
    const [newPoints, setNewPoints] = useState('');
    const [editingTweetPoint, setEditingTweetPoint] = useState<EditingPointState | null>(null);
    const [deletingPointId, setDeletingPointId] = useState<string | null>(null);
    const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
    const [showBotOnly, setShowBotOnly] = useState(false);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [referralsCurrentPage, setReferralsCurrentPage] = useState<number>(1);
    const REFERRALS_PER_PAGE = 6;

    const botReferees = userData?.user?.referees?.filter(referee => referee.isBot) || [];

    const searchUser = async (usernameToSearch: string) => {
        if (!usernameToSearch.trim()) {
            setError('Please enter a username');
            return;
        }

        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const data = await getUserComments(usernameToSearch.trim());
            if (data) {
                setUserData(data);
            } else {
                setError('No data found for this user');
            }
        } catch (err) {
            setError('Failed to fetch user data');
        } finally {
            setLoading(false);
        }
    };

    // Form submit handler
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();
        await searchUser(username);
    };

    // Effect for handling selectedUsername changes
    useEffect(() => {
        if (selectedUsername) {
            setUsername(selectedUsername);
            searchUser(selectedUsername);
        }
    }, [selectedUsername]);

    const handleBotStatusUpdate = async (newStatus: boolean) => {
        if (!userData?.user) return;

        try {
            await UpdateUserBot(userData.user.twitterUsername, newStatus);
            setUserData(prev => prev ? {
                user: {
                    ...prev.user,
                    isBot: newStatus,
                    isBotFlaggedDate: newStatus ? new Date().toISOString() : null
                }
            } : null);
            setSuccessMessage('Bot status updated successfully');
        } catch (err) {
            setError('Failed to update bot status');
        }
    };

    const handlePointsUpdate = async () => {
        if (!userData?.user || !newPoints) return;

        try {
            await updateUserPoints(userData.user.twitterUsername, parseInt(newPoints));
            setUserData(prev => prev ? {
                user: {
                    ...prev.user,
                    pointsEarned: parseInt(newPoints)
                }
            } : null);
            setEditingPoints(false);
            setSuccessMessage('Points updated successfully');
        } catch (err) {
            setError('Failed to update points');
        }
    };

    const handleDelete = async () => {
        if (!userData?.user) return;

        try {
            await softDeleteUser(userData.user.twitterUsername);
            setSuccessMessage('User deleted successfully');
            setUserData(prev => prev ? {
                user: {
                    ...prev.user,
                    softDeleted: true
                }
            } : null);
        } catch (err) {
            setError('Failed to delete user');
        }
    };

    const getTweetPoints = (tweet: Tweet, userPoints: UserPoints[] | null): UserPoints | null => {
        if (!userPoints) return null;
        return userPoints.find(point => point.sourceId === tweet.id && point.source === 'twitter_reply_ai_evaluation') || null;
    };

    const handleUpdateTweetPoints = async (pointId: string, newPoints: number) => {
        try {
            await updateTweetPoints(pointId, newPoints);

            // Update local state
            setUserData(prev => {
                if (!prev) return null;
                return {
                    user: {
                        ...prev.user,
                        userPoints: prev.user.userPoints?.map(point =>
                            point.id === pointId
                                ? { ...point, points: newPoints }
                                : point
                        ) ?? null
                    }
                };
            });

            setSuccessMessage('Points updated successfully');
        } catch (err) {
            setError('Failed to update points');
        } finally {
            setEditingTweetPoint(null);
        }
    };

    const startEditingTweetPoints = (point: UserPoints) => {
        setEditingTweetPoint({
            pointId: point.id,
            newValue: point.points.toString()
        });
    };

    // Step 1: Show confirmation buttons
    const handleDeleteConfirm = (pointId: string) => {
        setConfirmDelete(pointId);
    };

    // Step 2: Cancel deletion
    const handleDeleteCancel = () => {
        setConfirmDelete(null);
    };

    // Step 3: Actually perform the deletion
    const handleDeletePoint = async (pointId: string) => {
        setDeletingPointId(pointId);
        setError('');
        setSuccessMessage('');

        try {
            await deleteUserPoint(pointId);

            // Update local state
            setUserData(prev => {
                if (!prev) return null;

                const pointToDelete = prev.user.userPoints?.find(p => p.id === pointId);
                if (!pointToDelete) return prev;

                return {
                    user: {
                        ...prev.user,
                        pointsEarned: prev.user.pointsEarned - pointToDelete.points,
                        userPoints: prev.user.userPoints?.filter(p => p.id !== pointId) ?? null
                    }
                };
            });

            setSuccessMessage('Points deleted successfully');
        } catch (err) {
            setError('Failed to delete points');
        } finally {
            setDeletingPointId(null);
            setConfirmDelete(null);
        }
    };

    return (
        <div className="space-y-6">
            {/* Search Form */}
            <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-6">User Profile Search</h2>
                <form onSubmit={handleSearch} className="flex gap-3">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter Twitter username"
                        className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                    />
                    <button
                        type="submit"
                        disabled={loading}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </form>
            </div>

            {/* Messages */}
            {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-md text-red-600">
                    {error}
                </div>
            )}
            {successMessage && (
                <div className="p-4 bg-green-50 border border-green-200 rounded-md text-green-600">
                    {successMessage}
                </div>
            )}

            {/* User Profile */}
            {userData?.user && (
                <div className="bg-white rounded-lg shadow p-6">
                    {/* User Info Section */}
                    <div className="border-b pb-6 mb-6">
                        <h3 className="text-lg font-medium mb-4">User Information</h3>
                        <div className="flex gap-4 items-start">
                            <div className="space-y-3">

                                <p>
                                    <span className="font-medium">Username:</span>{' '}
                                    <a
                                        href={`https://twitter.com/${userData.user.twitterUsername}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:text-blue-800"
                                    >
                                        @{userData.user.twitterUsername}
                                    </a>
                                </p>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">Bot Status:</span>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={userData.user.isBot}
                                            onChange={(e) => handleBotStatusUpdate(e.target.checked)}
                                            className="form-checkbox h-4 w-4 text-blue-600"
                                        />
                                        <span className="ml-2">{userData.user.isBot ? 'Bot' : 'Not Bot'}</span>
                                    </label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">Total Points:</span>
                                    {editingPoints ? (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="number"
                                                value={newPoints}
                                                onChange={(e) => setNewPoints(e.target.value)}
                                                className="w-24 px-2 py-1 border rounded"
                                            />
                                            <button
                                                onClick={handlePointsUpdate}
                                                className="text-green-600 hover:text-green-800"
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setEditingPoints(false)}
                                                className="text-gray-600 hover:text-gray-800"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <span
                                            onClick={() => {
                                                setEditingPoints(true);
                                                setNewPoints(userData.user.pointsEarned.toString());
                                            }}
                                            className="cursor-pointer hover:text-blue-600"
                                        >
                                            {userData.user.pointsEarned}
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">User boost:</span>
                                    <span>{userData.user.boost}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium">Current Level:</span>
                                    <span>{userData.user.currentLevel}</span>
                                </div>
                                <div>
                                    <button
                                        onClick={handleDelete}
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    >
                                        Delete User
                                    </button>
                                </div>
                            </div>
                            <div className="space-y-3">
                                {userData?.user?.referer?.[0] && <div>
                                    <span className="font-medium">Referer: </span>
                                    <span>{userData?.user?.referer?.[0]?.twitterUsername}</span>
                                </div>}
                            </div>
                        </div>
                        <PermissionGate permissions={PERMISSION_GROUPS.SUPER_ADMIN}>
                            <div className="mt-4">
                                <PointsBoost
                                    username={userData.user.twitterUsername}
                                    onSuccess={(data) => {
                                        setUserData(prev => {
                                            if (!prev) return null;
                                            return {
                                                user: {
                                                    ...prev.user,
                                                    pointsEarned: prev.user.pointsEarned + data.points
                                                }
                                            };
                                        });
                                        setSuccessMessage('Points added successfully');
                                    }}
                                    onError={(error) => setError(error)}
                                />
                            </div>
                        </PermissionGate>
                    </div>

                    {/* Referrals Section */}
                    {userData.user.referees && userData.user.referees.length > 0 && (
                        <div className="border-b pb-6 mb-6">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-lg font-medium">
                                    Referrals ({userData.user.referees.length}) / Bots: {botReferees.length}
                                </h3>
                                <div className="flex items-center gap-4">
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={showBotOnly}
                                            onChange={(e) => setShowBotOnly(e.target.checked)}
                                            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                        />
                                        <span className="ml-2 text-sm text-gray-700">Show Bot Referees Only</span>
                                    </label>
                                    <button
                                        onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        Sort by Points {sortOrder === 'asc' ? '↑' : '↓'}
                                    </button>
                                </div>
                            </div>
                            {(() => {
                                const filteredReferees = showBotOnly ? botReferees : userData.user.referees;

                                const sortedReferees = [...filteredReferees].sort((a, b) => {
                                    const pointsA = a.pointsEarned || 0;
                                    const pointsB = b.pointsEarned || 0;
                                    return sortOrder === 'asc' ? pointsA - pointsB : pointsB - pointsA;
                                });

                                const indexOfLastReferral = referralsCurrentPage * REFERRALS_PER_PAGE;
                                const indexOfFirstReferral = indexOfLastReferral - REFERRALS_PER_PAGE;
                                const currentReferrals = sortedReferees.slice(indexOfFirstReferral, indexOfLastReferral);
                                const totalReferralPages = Math.ceil(sortedReferees.length / REFERRALS_PER_PAGE);

                                return (
                                    <>
                                        <div className="text-gray-600 mb-4">
                                            Showing {indexOfFirstReferral + 1}-{Math.min(indexOfLastReferral, userData.user.referees.length)} of {userData.user.referees.length} referrals
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {currentReferrals.map((referee) => {
                                                const referralPoints = userData.user.userPoints?.filter(point =>
                                                    (point.source === 'referral' || point.source === 'cascading_referral') &&
                                                    point.sourceId === referee.id
                                                );

                                                return (
                                                    <div key={referee.id} className="p-4 border rounded-lg hover:bg-gray-50">
                                                        <div className="flex justify-between items-start mb-2">
                                                            <a
                                                                href={`https://twitter.com/${referee.twitterUsername}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-blue-600 hover:text-blue-800 font-medium"
                                                            >
                                                                @{referee.twitterUsername}
                                                            </a>
                                                            {referee.isBot && (
                                                                <span className="px-2 py-1 bg-red-100 text-red-800 text-xs rounded-full">
                                                                    Bot
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="text-sm text-gray-600 space-y-1">
                                                            <p>User Points: {referee.pointsEarned}</p>
                                                            <p>Referred: {new Date(referee.referralDate).toLocaleDateString()}</p>
                                                        </div>

                                                        {/* Points earned from this referral */}
                                                        {referralPoints && referralPoints.length > 0 && (
                                                            <div className="mt-3 space-y-2">
                                                                <h4 className="font-medium text-sm">Points Earned from Referral:</h4>
                                                                {referralPoints.map(point => (
                                                                    <div key={point.id}
                                                                        className="flex items-center justify-between p-2 bg-blue-50 rounded-md">
                                                                        <div className="text-sm">
                                                                            <p className="text-blue-800">{point.points} points</p>
                                                                            <p className="text-blue-600 text-xs">{point.source}</p>
                                                                        </div>
                                                                        {confirmDelete === point.id ? (
                                                                            <div className="flex items-center gap-2">
                                                                                <button
                                                                                    onClick={() => handleDeletePoint(point.id)}
                                                                                    disabled={deletingPointId === point.id}
                                                                                    className="px-2 py-1 text-xs bg-red-500 text-white rounded hover:bg-red-600"
                                                                                >
                                                                                    {deletingPointId === point.id ? '⏳' : 'Confirm'}
                                                                                </button>
                                                                                <button
                                                                                    onClick={handleDeleteCancel}
                                                                                    className="px-2 py-1 text-xs bg-gray-500 text-white rounded hover:bg-gray-600"
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <button
                                                                                onClick={() => handleDeleteConfirm(point.id)}
                                                                                className="p-1 text-red-600 hover:text-red-800"
                                                                                title="Delete points"
                                                                            >
                                                                                🗑️
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {userData.user.referees.length > REFERRALS_PER_PAGE && (
                                            <Pagination
                                                currentPage={referralsCurrentPage}
                                                totalPages={totalReferralPages}
                                                onPageChange={(page) => setReferralsCurrentPage(page)}
                                            />
                                        )}
                                    </>
                                )
                            })()}
                        </div>
                    )}

                    {/* Comments and Points Section */}
                    <div>
                        <h3 className="text-lg font-medium mb-4">Comments and Points History ({userData.user.tweets?.length} - {userData.user.userPoints?.filter((point) => point.source === 'twitter_reply_ai_evaluation').length})</h3>
                        <div className="space-y-4">
                            {userData.user.tweets?.map((tweet) => {
                                const relatedPoints = getTweetPoints(tweet, userData.user.userPoints);

                                return (
                                    <div key={tweet.id} className="p-4 border rounded-lg">
                                        <div className="flex justify-between items-start">
                                            <div className="flex-1">
                                                <p className="mb-2">{tweet.text}</p>
                                                <div className="flex gap-4 text-sm text-gray-600">
                                                    <span>{new Date(tweet.createdAt).toLocaleDateString()}</span>
                                                    <a
                                                        href={`https://twitter.com/anyuser/status/${tweet.tweetId}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 hover:text-blue-800"
                                                    >
                                                        View Tweet
                                                    </a>
                                                    {tweet.publicMetrics && (
                                                        <div className="flex gap-2">
                                                            <span>💬 {tweet.publicMetrics.replyCount}</span>
                                                            <span>🔄 {tweet.publicMetrics.retweetCount}</span>
                                                            <span>❤️ {tweet.publicMetrics.likeCount}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {relatedPoints && (
                                                <div className="ml-4 p-3 bg-blue-50 rounded-md min-w-[200px]">
                                                    <h4 className="font-medium text-blue-800 mb-1">Points Earned</h4>
                                                    <div className="text-sm text-blue-600">
                                                        {editingTweetPoint?.pointId === relatedPoints.id ? (
                                                            <div className="space-y-2">
                                                                <div className="flex items-center gap-2">
                                                                    <input
                                                                        type="number"
                                                                        value={editingTweetPoint.newValue}
                                                                        onChange={(e) => setEditingTweetPoint({
                                                                            ...editingTweetPoint,
                                                                            newValue: e.target.value
                                                                        })}
                                                                        className="w-20 px-2 py-1 border rounded text-gray-800"
                                                                    />
                                                                    <span>points</span>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <button
                                                                        onClick={() => handleUpdateTweetPoints(
                                                                            relatedPoints.id,
                                                                            parseInt(editingTweetPoint.newValue)
                                                                        )}
                                                                        className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 text-xs"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <button
                                                                        onClick={() => setEditingTweetPoint(null)}
                                                                        className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600 text-xs"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="cursor-pointer hover:bg-blue-100 p-1 rounded"
                                                                onClick={() => startEditingTweetPoints(relatedPoints)}
                                                            >
                                                                <p>Points: {relatedPoints.points}</p>
                                                                {relatedPoints.evaluationScore && (
                                                                    <p>Evaluation Score: {relatedPoints.evaluationScore}</p>
                                                                )}
                                                                <p>Source: {relatedPoints.source}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfileView;