// App.tsx
import { ReactNode, useEffect, useState } from "react";
import Whitelist from "./components/Whitelist";
import Collections from "./components/Collections";
import Pages from "./components/Pages";
import WhitelistByEmail from "./components/WhitelistByEmail";
import PageOwner from "./components/PageOwner";
import NavMenu from "./components/NavMenu";
import Airtable from "./components/Airtable";
import UserRight from "./components/UserRight";
import Quests from "./components/Quests";
import { ApiKeyInput } from "./components/ApiKeyInput";
import { ApiKeyProvider, useApiKey } from "./context/ApiKeyContext";
import { fetchClient } from "./services/fetchClient";
import SocialAdmin from "./components/Social admin/SocialAdmin";
import TweetsManagement from "./components/Social admin/TweetsManagement/TweetsManagement";

interface MainContentProps {
  children: ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  const { apiKey } = useApiKey();

  useEffect(() => {
    fetchClient.setApiKey(apiKey);
  }, [apiKey]);

  if (!apiKey) {
    return <ApiKeyInput />;
  }

  return <>{children}</>;
};

function App() {
  const [selectedTab, setSelectedTab] = useState<number>(-1);

  const handleOnClickTab = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <ApiKeyProvider>
      <MainContent>
        <div className="flex h-screen bg-gray-50">
          <NavMenu handleOnClickTab={handleOnClickTab} selectedTab={selectedTab} />
          <div className="flex-1 overflow-auto p-6">
            <div className="max-w-7xl mx-auto space-y-6">
              {selectedTab === 0 && (
                <div className="space-y-6">
                  <Whitelist />
                  <WhitelistByEmail />
                  <UserRight />
                </div>
              )}
              {selectedTab === 1 && <Collections />}
              {selectedTab === 2 && (
                <div className="space-y-6">
                  <Pages />
                  <PageOwner />
                </div>
              )}
              {selectedTab === 3 && <Airtable />}
              {selectedTab === 4 && <Quests />}
              {selectedTab === 5 && <SocialAdmin />}
              {selectedTab === 6 && <TweetsManagement />}
            </div>
          </div>
        </div>
      </MainContent>
    </ApiKeyProvider>
  );
}

export default App;