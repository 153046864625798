import { Tweet2 } from "../components/Social admin/types";

export type FollowerTier = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export function getFollowerTier(followerCount: number): FollowerTier {
  if (followerCount < 100) return 0;
  if (followerCount < 1000) return 1;
  if (followerCount < 5000) return 2;
  if (followerCount < 10000) return 3;
  if (followerCount < 50000) return 4;
  if (followerCount < 100000) return 5;
  return 6;
}

export function groupTweetsByFollowerTier(
  tweets: Tweet2[],
): Record<FollowerTier, Tweet2[]> {
  const result: Record<FollowerTier, Tweet2[]> = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  tweets.forEach((tweet) => {
    const followerCount = tweet.author_data.public_metrics.followers_count;

    const tier = getFollowerTier(followerCount);

    result[tier].push(tweet);
  });

  return result;
}
