import { PERMISSION_GROUPS, PERMISSIONS } from './permissions';

export const NAV_PERMISSION_GROUPS = {
    USER_MANAGEMENT: {
        index: 0,
        label: "Whitelist",
        requiredPermissions: PERMISSION_GROUPS.CM,
        icon: "Users",
        description: "Manage user whitelist",
        sections: ["Whitelist", "WhitelistByEmail", "UserRight"]
    },
    COLLECTIONS: {
        index: 1,
        label: "Collections",
        requiredPermissions: [PERMISSIONS.REFRESH_COLLECTIONS],
        icon: "LibraryBig",
        description: "View and manage collections"
    },
    PAGES: {
        index: 2,
        label: "Pages",
        requiredPermissions: [
            PERMISSIONS.SEARCH_PAGES,
            PERMISSIONS.UPDATE_PAGES,
            PERMISSIONS.VIEW_PAGE_OWNER
        ],
        icon: "FileText",
        description: "Manage pages and content"
    },
    AIRTABLE: {
        index: 3,
        label: "Airtable",
        requiredPermissions: [PERMISSIONS.REFRESH_AIRTABLE],
        icon: "Database",
        description: "Airtable integration"
    },
    QUESTS: {
        index: 4,
        label: "Quests",
        requiredPermissions: [PERMISSIONS.VIEW_QUESTS, PERMISSIONS.MANAGE_QUESTS],
        icon: "Trophy",
        description: "Manage quests"
    },
    SOCIAL_ADMIN: {
        index: 5,
        label: "Social Admin",
        requiredPermissions: PERMISSION_GROUPS.AI_MODERATOR,
        icon: "UserCog",
        description: "Social media administration"
    },
    TWEETS_MANAGEMENT: {
        index: 6,
        label: "Tweets Management",
        requiredPermissions: [
            PERMISSIONS.MANAGE_TWEETS,
            PERMISSIONS.MANAGE_TWEET_POINTS
        ],
        icon: "MessageSquare",
        description: "Manage tweets and interactions"
    }
};

export const hasRequiredPermissionsForNav = (
    userPermissions: string[],
    navItem: keyof typeof NAV_PERMISSION_GROUPS
): boolean => {
    // Check if user has super admin permissions
    if (userPermissions.some(p => PERMISSION_GROUPS.SUPER_ADMIN.includes(p))) {
        return true;
    }

    // Get required permissions for the nav item
    const requiredPermissions = NAV_PERMISSION_GROUPS[navItem].requiredPermissions;

    // Check if user has any of the required permissions
    return requiredPermissions.some(permission => userPermissions.includes(permission));
};