// constants/permissions.constants.ts

// Individual permissions matching backend
export const PERMISSIONS = {

    SUPER_ADMIN: "super_admin",

    // User permissions
    WHITELIST_USERS: "whitelist_users",
    MANAGE_USER_RIGHTS: "manage_user_rights",

    // Page permissions
    SEARCH_PAGES: "search_pages",
    UPDATE_PAGES: "update_pages",
    TRANSFER_PAGES: "transfer_pages",
    VIEW_PAGE_OWNER: "view_page_owner",
    MANAGE_PAGE_OWNERS: "manage_page_owners",

    // Collection permissions
    REFRESH_COLLECTIONS: "refresh_collections",

    // Airtable permissions
    REFRESH_AIRTABLE: "refresh_airtable",

    // Quest permissions
    VIEW_QUESTS: "view_quests",
    MANAGE_QUESTS: "manage_quests",

    // Social permissions
    MANAGE_USER_SOCIAL: "manage_user_social",
    VIEW_USER_COMMENTS: "view_user_comments",
    MANAGE_BOT_FLAGS: "manage_bot_flags",
    VIEW_BOT_FLAGS: "view_bot_flags",
    MANAGE_TWEET_POINTS: "manage_tweet_points",
    MANAGE_TWEETS: "manage_tweets",

    // API permissions
    MANAGE_API_KEYS: "manage_api_keys",
    VIEW_API_KEYS: "view_api_keys",
};

// Tab permissions mapping using the new permission constants
export const TAB_PERMISSIONS: Record<number, string[]> = {
    // Whitelist tab (User Management)
    0: [
        PERMISSIONS.WHITELIST_USERS,
        PERMISSIONS.MANAGE_USER_RIGHTS
    ],

    // Collections tab
    1: [
        PERMISSIONS.REFRESH_COLLECTIONS
    ],

    // Pages tab
    2: [
        PERMISSIONS.SEARCH_PAGES,
        PERMISSIONS.UPDATE_PAGES,
        PERMISSIONS.VIEW_PAGE_OWNER,
        PERMISSIONS.MANAGE_PAGE_OWNERS
    ],

    // Airtable tab
    3: [
        PERMISSIONS.REFRESH_AIRTABLE
    ],

    // Quests tab
    4: [
        PERMISSIONS.VIEW_QUESTS,
        PERMISSIONS.MANAGE_QUESTS
    ],

    // Social Admin tab
    5: [
        PERMISSIONS.MANAGE_USER_SOCIAL,
        PERMISSIONS.VIEW_USER_COMMENTS,
        PERMISSIONS.MANAGE_BOT_FLAGS,
        PERMISSIONS.VIEW_BOT_FLAGS
    ],

    // Tweets Management tab
    6: [
        PERMISSIONS.MANAGE_TWEETS,
        PERMISSIONS.MANAGE_TWEET_POINTS
    ]
};

// Permission groups for easier role-based checks
export const PERMISSION_GROUPS = {
    SUPER_ADMIN: [PERMISSIONS.SUPER_ADMIN],

    ADMIN: [
        PERMISSIONS.WHITELIST_USERS,
        PERMISSIONS.SEARCH_PAGES,
        PERMISSIONS.UPDATE_PAGES,
        PERMISSIONS.VIEW_PAGE_OWNER,
        PERMISSIONS.REFRESH_COLLECTIONS,
    ],

    CM: [
        PERMISSIONS.WHITELIST_USERS,
        PERMISSIONS.SEARCH_PAGES,
        PERMISSIONS.VIEW_PAGE_OWNER,
    ],

    AI_MODERATOR: [
        PERMISSIONS.MANAGE_USER_SOCIAL,
        PERMISSIONS.VIEW_USER_COMMENTS,
        PERMISSIONS.MANAGE_BOT_FLAGS,
        PERMISSIONS.VIEW_BOT_FLAGS,
    ]
};